export const setError = error => ({
  type: 'SET_ERROR',
  error
})

export const setIntegrationDataDisplay = integrationData => ({
  type: 'SET_INTEGRATIONDATA',
  integrationData
})

export const setAppContentState = payload => ({
  type: 'SET_APPCONTENTSTATE',
  payload
})

export const setTemplateDataDisplay = templateData => ({
  type: 'SET_TEMPLATEDATA',
  templateData
})

export const setUserDataDisplay = userData => ({
  type: 'SET_USERDATA',
  userData
})

export const setTempUserDataDisplay = tempUserData => ({
  type: 'SET_TEMPUSERDATA',
  tempUserData
})

export const setSignState = payload => ({
  type: 'SET_SIGNSTATE',
  payload
})

export const setManageUserSaveState = payload => ({
  type: 'SET_MANAGEUSERSAVESTATE',
  payload
})

export const setIntegrationTagDataDisplay = payload => ({
  type: 'SET_INTEGRATIONTAGDATA',
  payload
})

export const setTempIntegrationTagDataDisplay = payload => ({
  type: 'SET_TEMPINTEGRATIONTAGDATA',
  payload
})

export const setManageIntegrationTagSaveState = payload => ({
  type: 'SET_MANAGEINTEGRATIONTAGSAVESTATE',
  payload
})

