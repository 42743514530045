// import React, {useState} from 'react';
import "@fontsource/roboto";

import API from "../helpers/api";
import {useEffect, useState} from 'react';
import {validateCommaSeperatedListofEmails} from "../helpers/validation"
import {templateState} from "../state/atoms"
import {useRecoilState} from 'recoil'

interface DisplayTemplateSettingsProps {
  id: number
}
function DisplayTemplateSettings({ id }: DisplayTemplateSettingsProps) {
  const [emailToForward, setEmailToForward] = useState("")
  const [forwardEnabled, setForwardEnabled] = useState(false)
  const [templates, setTemplates] = useRecoilState(templateState); //eslint-disable-line
  const [buttonStyle, setButtonStyle] = useState("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")
  const [inputError, setInputError] = useState(false)


  useEffect(() => {
    setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl");
    (async () => {
      try {
        let index = templates.findIndex(x => x.id === id)
        if (index !== -1) {
          //Serialize
          console.log(templates[index])
          let settings = JSON.parse(templates[index].settings)
          //Set Settings
          setEmailToForward(settings?.emailToForward ?? "")
          setForwardEnabled(settings?.forwardEnabled ?? false)
        }
      } catch (error) {
          setEmailToForward("")
          setForwardEnabled(false)
      }
      
    })();
   }, [])
   
   const OnSave = async () => {
     try {
       //Validate
      if(!validateCommaSeperatedListofEmails(emailToForward)) {
        throw new Error("incorrect input");
      }
      //Stringify
      let settings = JSON.stringify({
        emailToForward: emailToForward,
        forwardEnabled: forwardEnabled
      })

      console.log(id)
      console.log(settings)
      //Update Serverside Settings
      await API.PutTemplateSettings(settings, id)
      setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-green-400 rounded shadow-xl")
      //Update forms again
      let data  = await API.GetTemplates();

      let mappedData = data.map(x => {return {settings: x.settings, id: x.id, name: x.name, size: x.size, lastModifiedDateTime: x.lastModifiedDateTime, contentLocation: x.contentLocation, contentBytes: x.contentBytes, subscription: x.subscription, showContext: false}})
      setTemplates(mappedData)

      //Handle Error if Input
     } catch (error) {
      setInputError(true)
     }
   }

  return (
    <div className="h-auto">
      <h1 className="text-2xl font-roboto mb-6">Template Settings</h1>
      <div className="grid gap-10 grid-row-6 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 md:h-full font-roboto">
        <div>
          <h2>Forward Rule</h2>
        </div>
        <div className="col-span-2">
            <label className="block text-gray-700 text-md font-roboto mb-2" htmlFor="hostnameemail">
            Forward Templates to the Following Email Address
            </label>
            <input value={emailToForward} onChange={(x) => setEmailToForward(x.target.value)} className={`shadow appearance-none border ${inputError === true && "border-red-500"} rounded w-80 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} id="hostnamemail" type="text" placeholder="me@mycompany.com"/>
            <label className="block text-gray-800" htmlFor="flexCheckDefault">
              Enabled
            </label>
            <input checked={forwardEnabled} onChange={(x) => setForwardEnabled(x.target.checked)}  type="checkbox" id="flexCheckDefault"/>
        </div>
        </div>
        <div className="col-span-2"> 
          <div className="flex justify-left mt-2">
            <div className="h-10"></div>
            <button className={buttonStyle} onClick={()=>OnSave()}>Save</button>
          </div>
        </div>
    </div>
    
  );
}

export default DisplayTemplateSettings;
