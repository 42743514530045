import {
    SET_MANAGEUSERSAVESTATE
  } from '../constants/ActionTypes'
  
  const initialState = [
    {
      saveUserData: false
    }
  ]
  
  export default function saveUserData(state = initialState, action: any) {
    switch (action.type) {
      case SET_MANAGEUSERSAVESTATE:
        return [
          {
            saveUserData: action.payload
          }
        ]
      default:
        return state
    }
  }
  