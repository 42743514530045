import {
    SET_USERDATA
  } from '../constants/ActionTypes'
  
  const initialState = [
    {
      userData: []
    }
  ]
  
  export default function userData(state = initialState, action: any) {
    switch (action.type) {
      case SET_USERDATA:
        return [
          {
            userData: action.userData
          }
        ]
      default:
        return state
    }
  }
  