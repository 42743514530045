import {
  SET_INTEGRATIONDATA
} from '../constants/ActionTypes'

const initialState = [
  {
    integrationData: []
  }
]

export default function integrationData(state = initialState, action: any) {
  switch (action.type) {
    case SET_INTEGRATIONDATA:
      return [
        {
          integrationData: action.integrationData
        }
      ]
    default:
      return state
  }
}
