// import React, {useState} from 'react';
import "@fontsource/roboto";
import { connect } from 'react-redux';
import API from "../helpers/api";
import { store } from '../index'
import { useEffect } from 'react';
import { TrashIcon } from '@heroicons/react/solid';
import { setUserDataDisplay } from "../redux/actions";
import { setTempUserDataDisplay } from "../redux/actions";
import { setManageUserSaveState } from "../redux/actions";
import { SideActionContentUser } from "./SideActions/SideActionContentUser";
import { useRecoilState } from "recoil";
import { ActionState, DisplaySideActionState, ListItemIdState, ListState, UpdateUserState, UserDataState } from "../state/atoms";

function DisplayUsers(props) {
  const [displaySideAction, setDisplaySideAction] = useRecoilState(DisplaySideActionState)
  const [action, setAction] = useRecoilState(ActionState)
  const [itemId, setItemId] = useRecoilState(ListItemIdState)
  const [userData, SetUserData] = useRecoilState(UserDataState)
  const [UpdateUserDisplay, SetUpdateUserDisplay] = useRecoilState(UpdateUserState)
  const [list, setList] = useRecoilState(ListState)
  const handleDelete = async () => {
    console.log(props.tempUserData[0].tempUserData)
      console.log(list)
      const raw = list as unknown as number[]
      //Removes duplicate and doubleclicked entries
      const counts = raw.reduce((acc:any, value) => ({
          ...acc,
          [value]: (acc[value] || 0) + 1
      }), {});
      var result = Object.keys(counts).map((key) => [Number(key), counts[key]]);
      const deleted = result.filter(x => {
          if (x[1] % 2 === 1) {
              return x
          }
      }).map(x => x[0])
      
      await API.DeleteUsers(deleted);
      SetUpdateUserDisplay(!UpdateUserDisplay)
      setDisplaySideAction(false)
  }
  useEffect(() => {
    if (props.saveUserData[0].saveUserData !== true) {
      // const g = props.tempUserData[0].tempUserData.splice()
      // console.log("Data to Save")
      // console.log(g)
      // // console.log()
      // store.dispatch(setUserDataDisplay(g));
    }
  }, [props])

  useEffect(() => {
    (async () => {
      let res = await API.GetUsers()

      if (res[1] === 200) {
        // console.log(res[0])
        SetUserData([...res[0]])
        store.dispatch(setTempUserDataDisplay([...res[0]]));
        store.dispatch(setManageUserSaveState(false));
        console.log("Updated Original")
        store.dispatch(setUserDataDisplay( JSON.parse(JSON.stringify([...res[0]]))));
        // setData(res[0])
        // setTempData(res[0])
      } else {
        //FIX ERROR
        // console.log("Was a problem loading user data")
      }

      // console.log(props.admin[0].templateData)
    })();
  }, [])

  useEffect(() => {
    (async () => {
      let res = await API.GetUsers()

      if (res[1] === 200) {
        // console.log(res[0])
        SetUserData([...res[0]])
        store.dispatch(setTempUserDataDisplay([...res[0]]));
        store.dispatch(setManageUserSaveState(false));
        console.log("Updated Original")
        store.dispatch(setUserDataDisplay( JSON.parse(JSON.stringify([...res[0]]))));
        // setData(res[0])
        // setTempData(res[0])
      } else {
        //FIX ERROR
        // console.log("Was a problem loading user data")
      }

      // console.log(props.admin[0].templateData)
    })();
  }, [UpdateUserDisplay])

  return (
    <div className="h-auto ml-9 mt-9">
      <div className="flex flex-row">
        <div className="flex flex-col flex-grow pr-10">
          <div className="flex flex-row justify-between pb-6">
                <input type="text" placeholder="Search" className="placeholder-[#373E3D] px-3 w-40 rounded-xl h-7 shadow-inner border lg:w-44 opacity-0"/>
                <div className="flex flex-row self-end gap-x-3">
                    <div onClick={() => handleDelete()}  className="bg-red-400 flex items-center justify-center rounded-full h-6 w-6">
                        <svg className="h-3 w-3"  viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.6875 1.5L6.0625 0.875H2.9375L2.3125 1.5H0.125V2.75H8.875V1.5H6.6875ZM0.75 10.875C0.75 11.5625 1.3125 12.125 2 12.125H7C7.6875 12.125 8.25 11.5625 8.25 10.875V3.375H0.75V10.875ZM2 4.625V7.75V10.875H7V4.625H2Z" fill="white"/>
                        </svg>
                    </div>
                    {
                        //Block button
                    }
                    {/* <div className="bg-[#f1f1f1] flex items-center justify-center rounded-full h-6 w-6">                        
                        <svg className="h-3 w-3"  viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.99935 0.166504C2.77935 0.166504 0.166016 2.77984 0.166016 5.99984C0.166016 9.21984 2.77935 11.8332 5.99935 11.8332C9.21935 11.8332 11.8327 9.21984 11.8327 5.99984C11.8327 2.77984 9.21935 0.166504 5.99935 0.166504ZM5.99935 10.6665C3.42102 10.6665 1.33268 8.57817 1.33268 5.99984C1.33268 4.92067 1.70018 3.929 2.31852 3.1415L8.85768 9.68067C8.07018 10.299 7.07852 10.6665 5.99935 10.6665ZM3.14102 2.319L9.68018 8.85817C10.2985 8.07067 10.666 7.079 10.666 5.99984C10.666 3.4215 8.57768 1.33317 5.99935 1.33317C4.92018 1.33317 3.92852 1.70067 3.14102 2.319Z" fill="black" fillOpacity="0.54"/>
                        </svg>
                    </div> */}
                    <div onClick={() => {setDisplaySideAction(true);setAction("Create");}}  className="bg-blue-400 flex items-center justify-center rounded-full h-6 w-6">                        
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="#fff" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                    </div>
                </div>
          </div>
          <table className="table-auto w-full mb-36 text-gray-600 ">
          <thead className="text-left">
              <tr className="h-full h-8">
                <th></th>
                <th className="text-left pl-2 ">Username</th>
                <th className="text-left pl-6 ">Firstname</th>
                <th className="text-left pl-2 ">Lastname</th>
                <th className="text-left pl-2 ">Displayname</th>
                <th className="text-left pl-2 ">Email</th>
                <th className="text-left pl-2 ">Role</th>
                <th className="text-left pl-2 "></th>
              </tr>
            </thead>
            <tbody className="text-left ">
              {props.tempUserData[0].tempUserData.map((item, index) => {
                const isDisabled = (index === 0) || (index === 1) || (index === 2)
                return (
                  <tr className={isDisabled ? "text-gray-300 h-10 shadow-top" : "shadow-top  h-10"} key={index}>
                    <td><input onClick={()=>{setList([...list, item.id])}} disabled={isDisabled ? true : false} className={`form-check-input disabled:opacity-0 appearance-none rounded-full h-4 w-4 border border-gray-300 shadow-inner bg-[#F1F1F1] checked:bg-[#999999] checked:border-4 checked:border-invisible checked: checked:shadow-none focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left  cursor-pointer`} type="checkbox" name="flexRadioDefault" id="flexRadioDefault20"  /></td>
                    {item.metaAddUser === 1 ? <td className=" w-20"><input className="pl-2 w-full h-full" type="text" name="" id="" value={item.username}/></td> 
                    : <td onClick={()=>{isDisabled ? (()=>{})()  : (()=> {setDisplaySideAction(true);setAction("Edit");setItemId(item.id)})()}} className={isDisabled ? "pl-2" : " w-20 pl-2  select-none"}>{item.username}</td>}
                    <td className="pl-6" onClick={()=>{isDisabled ? (()=>{})()  : (()=> {setDisplaySideAction(true);setAction("Edit");setItemId(item.id)})()}}>{item.firstName  ?? ""}</td>
                    <td className="pl-2" onClick={()=>{isDisabled ? (()=>{})()  : (()=> {setDisplaySideAction(true);setAction("Edit");setItemId(item.id)})()}}>{item.lastName ?? ""}</td>
                    <td className="pl-2" onClick={()=>{isDisabled ? (()=>{})()  : (()=> {setDisplaySideAction(true);setAction("Edit");setItemId(item.id)})()}}>{item.displayname}</td>
                    {item.metaAddUser === 1 ? <td className=" w-20"><input className="pl-2 w-full h-full" type="text" name="" id="" value={item.email}/></td> 
                    : <td  onClick={()=>{isDisabled ? (()=>{})()  : (()=> {setDisplaySideAction(true);setAction("Edit");setItemId(item.id)})()}} className={isDisabled ? "" : "  w-20 pl-2 select-none"}>{item.email}</td>}
                    {item.metaAddUser === 1 ? 
                    <td onClick={()=>{isDisabled ? (()=>{})()  : (()=> {setDisplaySideAction(true);setAction("Edit");setItemId(item.id)})()}} className=" w-20">
                      <select className="pl-2 w-full h-full" value={item.role}>
                        <option disabled value=""></option>
                        <option value="User">User</option>
                        <option value="Admin">Admin</option>
                        <option value="Service">Service</option>
                      </select>
                    </td>
                    : <td onClick={()=>{isDisabled ? (()=>{})()  : (()=> {setDisplaySideAction(true);setAction("Edit");setItemId(item.id)})()}} className={isDisabled ? "" : " w-20 pl-2  select-none"}>{item.role}</td>}
                    <td onClick={()=>{isDisabled ? (()=>{})()  : (()=> {setDisplaySideAction(true);setAction("Edit");setItemId(item.id)})()}} className="  " >
                          <svg className="ml-auto" width="23" height="24" viewBox="0 0 23 24" fill="#C4C4C4" xmlns="http://www.w3.org/2000/svg">
                              <g id="keyboard_arrow_right_24px">
                                  <path id="icon/hardware/keyboard_arrow_right_24px" d="M7.94922 16.59L12.3384 12L7.94922 7.41L9.30047 6L15.0505 12L9.30047 18L7.94922 16.59Z" fill="black" fillOpacity="0.54"/>
                              </g>
                          </svg>
                      </td>
                  </tr>
                )
              }

                
              )}
            </tbody>
          </table>
        </div>
        
        <div className={`${displaySideAction ? "" : "hidden"} relative h-screen w-1/3 bg-[#f1f1f1] drop-shadow-[0_4px_3px_rgba(0,0,0,0.25)] rounded-tl-xl p-14`}>
            <div className="h-4 w-4 absolute top-0 right-0 m-6" onClick={x=>setDisplaySideAction(false)} >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.1673 1.7925L11.8748 0.5L6.75065 5.62417L1.62648 0.5L0.333984 1.7925L5.45815 6.91667L0.333984 12.0408L1.62648 13.3333L6.75065 8.20917L11.8748 13.3333L13.1673 12.0408L8.04315 6.91667L13.1673 1.7925Z" fill="#3F3C3C"/>
                </svg>
            </div>
            <SideActionContentUser />
        </div>
      </div>

      
    </div>
  );
}
const mapStateToProps = state => ({
  tempUserData: state.tempUserData,
  userData: state.userData,
  saveUserData: state.saveUserData
})
export default connect(mapStateToProps)(DisplayUsers);
