import { combineReducers } from 'redux'
import admin from './admin'
import appContent from './appContent'
import integrationData from './integrationData'
import templateData from './templateData'
import userData from './userData'
import saveUserData from './saveUserData'
import tempUserData from './tempUserData'
import sign from './sign'

const sign365 = combineReducers({
  admin,
  integrationData,
  appContent,
  templateData,
  userData,
  tempUserData,
  saveUserData,
  sign
})

export default sign365
