import {
    SET_SIGNSTATE
  } from '../constants/ActionTypes'
  
  const initialState = [
    {
        signState: false
    }
  ]
  
  export default function sign(state = initialState, action: any) {
    switch (action.type) {
      case SET_SIGNSTATE:
        return [
          {
            signState: action.payload
          }
        ]
      default:
        return state
    }
  }
  