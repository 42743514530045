import "@fontsource/roboto";
import API from "../helpers/api";
import {
  useRecoilState, useRecoilValue
} from 'recoil';
import {templateRenderState, templateState} from "../state/atoms"
import {useEffect, useState} from 'react';
import DisplayTemplateSettings from "./DisplayTemplateSettings";
import UploadTemplate from "./UploadTemplate";

function DisplayTemplateData() {
  const [settingsIsDisplayed, setSettingsIsDisplayed] = useState(false);
  const [listIsDisplayed, setListIsDisplayed] = useState(true);
  const [selectedID, setSelectedId] = useState(-1);
  const [templates, setTemplates] = useRecoilState(templateState);
  const templateRenderRequired = useRecoilValue(templateRenderState);

  useEffect(() => {
    (async () => {
      let data  = await API.GetTemplates();
      let mappedData = data.map(x => {return {settings: x.settings, id: x.id, name: x.name, size: x.size, lastModifiedDateTime: x.lastModifiedDateTime, contentLocation: x.contentLocation, contentBytes: x.contentBytes, subscription: x.subscription, showContext: false}})
      setTemplates(mappedData)
    })();
   }, [templateRenderRequired])

  const onBackFromManage = () => {
    setSettingsIsDisplayed(false)
    setListIsDisplayed(true)
  }

  const onManage = (id) => {
    setSelectedId(id)
    setListIsDisplayed(false)
    setSettingsIsDisplayed(true)
    let i = templates.findIndex(x => x.id === id)
    let newTemplates = JSON.parse(JSON.stringify(templates))
    newTemplates[i].showContext = false
    setTemplates(newTemplates)
  }

  const onContext = (id, state) => {
    let i = templates.findIndex(x => x.id === id)
    let newTemplates = JSON.parse(JSON.stringify(templates))
    newTemplates[i].showContext = state
    setTemplates(newTemplates)
  }

  const onDelete = (id) => {
    (async () => {
      await API.DeleteTemplate(id);
      let data  = await API.GetTemplates();
      let mappedData = data.map(x => {return {settings: x.settings, id: x.id, name: x.name, size: x.size, lastModifiedDateTime: x.lastModifiedDateTime, contentLocation: x.contentLocation, contentBytes: x.contentBytes, subscription: x.subscription, showContext: false}})
      setTemplates(mappedData)
    })();
  }
  
  return (
    <div className="w-auto ml-9 mt-9">
      {listIsDisplayed && <div className="m-9"><UploadTemplate /></div>}
      {settingsIsDisplayed && <button onClick={onBackFromManage} className='float-right mr-4 font-bold text-blue-500 pt-1 flex flex-row'>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
        </svg>
        Back
      </button>}
      {listIsDisplayed && templates.map((item, index) => 
        <div className="h-10 shadow-top pt-2 w-full flex " key={index}>
          <div className='flex flex-grow' onClick={()=>onManage(item.id)}>
            <h1 className="font-roboto text-s font-bold">{item.name}</h1>
            <h1 className="font-roboto text-s italic ml-2">{item.lastModifiedDateTime}</h1>
          </div>
          <div className="relative ml-auto">
            <svg onClick={() => onContext(item.id, true)} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
            </svg>
            {item.showContext === true &&
            <div onMouseLeave={() => onContext(item.id, false)} className="absolute z-10 -bottom-14 right-0 bg-white shadow-md h-16 w-20 flex flex-col text-center py-2 rounded">
              <p onClick={()=>onManage(item.id)} className="">Manage</p>
              <p onClick={()=>onDelete(item.id)} className="text-red-600">Delete</p>
            </div>
            }
          </div>
        </div>
      )}
      {settingsIsDisplayed && <DisplayTemplateSettings id={selectedID}/>}
    </div>
  );
}
export default DisplayTemplateData;
