import moment from 'moment'
import { setError } from '../redux/actions'
import {store} from '../index'
import {toBase64, dateFormat} from './utility'
import { IUser } from '../state/interfaces';

export default class API {
    // REACT_APP_URI
    static endpoint: string = process.env.REACT_APP_URI ?? "";
    static token: string = "";

    static async PostToken(username: string, password: string) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "username": username,
        "password": password
        });

        var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        
        const response = await fetch(`https://${this.endpoint}/api/token`, requestOptions)
        
        return  [await response.text() ?? "", response.status];
    }
    
    static async GetUser(ltoken = "") {
        if(ltoken !== "") {
            this.token = ltoken
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        var requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        const response = await fetch(`https://${this.endpoint}/api/user`, requestOptions)

        if (response.status !== 200){
            return [{} , response.status]
        } else {
            return [await response.json() , response.status]
        }
    }

    static async GetUsers() {
        // console.log("Ran get users")
        if(this.token === "") {
            this.token = localStorage.getItem("token")
?? ""        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        var requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        const response = await fetch(`https://${this.endpoint}/api/user/list`, requestOptions)

        if (response.status !== 200){
            return [{} , response.status]
        } else {
            return [(await response.json()).map(x => { x.password = ""; x.metaAddUser = 0; x.metaError = 0; return x}) , response.status]
        }
    }

    static async PostUsers(users) {
        if(this.token === "") {
            this.token = localStorage.getItem("token")?? ""
        }
        let u: Array<IUser> = Array<IUser>() 
        u.push(users)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        myHeaders.append("Content-Type", "application/json");
        let rawU = JSON.parse(JSON.stringify(u))
        try {
            var raw = JSON.stringify(rawU);
        
                var requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
                };
                
                const response = await fetch(`https://${this.endpoint}/api/user`, requestOptions)
                switch (response.status) {
                    case 200:
                        break;
                
                    default:
                        console.log(response.status)
                        throw new Error("incorrect response")
                }
                return response.status
        } catch (e) {
            let ex = (e as Error)
            let error = Error();
                        error.name = "ServerError"
                        error.message = `The server was unable to process new users and returned the following message - ${ex.message}`
                        store.dispatch(setError(error));
                        
        }
    }
    static async PostUsers2(users) {
        if(this.token === "") {
            this.token = localStorage.getItem("token")?? ""
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        myHeaders.append("Content-Type", "application/json");
        let rawU = users
        try {
                 var raw = JSON.stringify(rawU);
        
                var requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
                };
                
                const response = await fetch(`https://${this.endpoint}/api/user`, requestOptions)
                switch (response.status) {
                    case 200:
                        break;
                
                    default:
                        console.log(response.status)
                        throw new Error("incorrect response")
                }
                return response.status
        } catch (e) {
            let ex = (e as Error)
            let error = Error();
                        error.name = "ServerError"
                        error.message = `The server was unable to process new users and returned the following message - ${ex.message}`
                        store.dispatch(setError(error));
                        
        }
    }

    static async DeleteUsers(ids) {
        if(this.token === "") {
            this.token = localStorage.getItem("token") ?? ""
        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        myHeaders.append("Content-Type", "application/json");

        try {
            var raw = JSON.stringify(ids);
            var requestOptions: RequestInit = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };
                
            const response = await fetch(`https://${this.endpoint}/api/user`, requestOptions)
            switch (response.status) {
                case 200:
                    break;
            
                default:
                    throw new Error("incorrect response")
            }
            return response.status
        } catch (e) {
            let ex = (e as Error)
            let error = Error();
                        error.name = "ServerError"
                        error.message = `The server was unable to delete the user and returned the following message - ${ex.message}`
                        store.dispatch(setError(error));
                        
        }
    }

    static async GetTemplates() {
        if(this.token === "") {
            this.token = localStorage.getItem("token")
?? ""        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        var requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        const response = await fetch(`https://${this.endpoint}/api/template`, requestOptions)
        return await response.json();
    }

    static async PostTemplate(file: File) {
        if(this.token === "") {
            this.token = localStorage.getItem("token")
?? ""        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        myHeaders.append("Content-Type", "application/json");

        try {
            var raw = JSON.stringify({
                "lastModifiedDateTime": moment(file.lastModified).format(dateFormat),
                "name": file.name,
                "contentBytes": (await toBase64(file) as string).split(',')[1]
                });
        
                var requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
                };
                
                const response = await fetch(`https://${this.endpoint}/api/template`, requestOptions)
                switch (response.status) {
                    case 201:
                        break;
                
                    default:
                        throw new Error("incorrect response")
                }
                return response.status
        } catch (e) {
            let ex = (e as Error)
            let error = Error();
                        error.name = "ServerError"
                        error.message = `The server was unable to process the template and returned the following message - ${ex.message}`
                        store.dispatch(setError(error));
                        
        }
    }

    static async PutTemplateSettings(settings: string, id: number) {
        if(this.token === "") {
            this.token = localStorage.getItem("token")
?? ""        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "settings": settings
        });

        var requestOptions: RequestInit = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`https://${this.endpoint}/api/template/${id}`, requestOptions)
        .then(response => response.status)
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    static async DeleteTemplate(id:string) {
        if(this.token === "") {
            this.token = localStorage.getItem("token")
?? ""        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        var requestOptions: RequestInit = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
        };

        await fetch(`https://${this.endpoint}/api/template/${id}`, requestOptions)
    }


    static async GetIntegrationTags() {
        if(this.token === "") {
            this.token = localStorage.getItem("token")
?? ""        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        var requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        try {
            const response = await fetch(`https://${this.endpoint}/api/integration`, requestOptions)
            return await response.json();
        } catch (error) {
            return []
        }
    }

    static async GetIntegrationData(id:string) {
        if(this.token === "") {
            this.token = localStorage.getItem("token")
?? ""        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        var requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };


        const response = await fetch(`https://${this.endpoint}/api/integration/${id}/data`, requestOptions)
        return await response.json();
    }

    static async GetAllIntegrationData() {
        const tags = await this.GetIntegrationTags();
        const data = []
        for (const element of tags) {
            data.concat(await this.GetIntegrationData(element.id));
        }
        return data
    }

    static async PostWorkforceOneSettings(settings: string, id: number) {
        if(this.token === "") {
            this.token = localStorage.getItem("token")
?? ""        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "settings": settings
        });

        var requestOptions: RequestInit = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`https://${this.endpoint}/api/integration/${id}`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    static async PostIntegrationData(file: File, id: number, key: string, value: string) {
        if(this.token === "") {
            this.token = localStorage.getItem("token")
?? ""        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.token}`);
        myHeaders.append("Content-Type", "application/json");

        try {
            var raw = JSON.stringify({
                "name": file.name,
                "contentBytes": await toBase64(file),
                "integrationKey": key,
                "integrationValue": value
            });
        
            var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };
            
            const response = await fetch(`https://${this.endpoint}/api/integration/${id}/data/csv`, requestOptions)
            return response.status
        } catch (e) {
            let ex = (e as Error)
            let error = Error();
            error.name = "ServerError"
            error.message = `The server was unable to process the template and returned the following message - ${ex.message}`
            store.dispatch(setError(error));
        }
    }
}