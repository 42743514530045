import "@fontsource/roboto";
import API from "../helpers/api";
import {useEffect, useState} from 'react';
import {User} from '../helpers/interfaces'
interface UploadWorkforceOneSettingsProps {
  id: number;
}

function UploadWorkforceOneSettings({id} : UploadWorkforceOneSettingsProps) {
  interface IValidation {
    validFailedProcessingEmail: boolean
    validMailHostname: boolean,
    validMailPort: boolean,
    validMailUsername:boolean,
    validMailPassword: boolean,
    validWOHostname: boolean,
    validWOUsername: boolean,
    validWOPassword: boolean,
    canSave: boolean
  }
  const [didSave, setDidSave] = useState(false)
  const [emailHost, setEmailHost] = useState("")
  const [emailPort, setEmailPort] = useState(0)
  const [emailUsername, setEmailUsername] = useState("")
  const [emailPassword, setEmailPassword] = useState("")
  const [emailSSL, setEmailSSL] = useState(false)
  const [workforceoneHost, setWorkforceoneHost] = useState("")
  const [workforceoneUsername, setWorkforceoneUsername] = useState("")
  const [workforceonePassword, setWorkforceonePassword] = useState("")
  const [failedProcessingEmail, setFailedProcessingEmail] = useState("")
  const [users, setUsers] = useState<User[]>([])
  const [v, setV] = useState<IValidation>(
    {
        validFailedProcessingEmail: true,
        validMailHostname: true,
        validMailPassword: true,
        validMailPort: true,
        validMailUsername: true,
        validWOHostname: true,
        validWOPassword: true,
        validWOUsername: true,
        canSave: true
    } as IValidation
  )
  const validate = () => {
    let mV: IValidation = {
      validFailedProcessingEmail: true,
      validMailHostname: true,
      validMailPassword: true,
      validMailPort: true,
      validMailUsername: true,
      validWOHostname: true,
      validWOPassword: true,
      validWOUsername: true,
      canSave: true
    }
    mV.validFailedProcessingEmail = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(failedProcessingEmail) === false) ? false : true
    mV.validMailHostname = (/^(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)$/.test(emailHost) === false) ? false : true
    mV.validMailUsername = (/^[a-zA-Z0-9\.\@\s_-]{0,100}$/gm.test(emailUsername) === false) ? false : true
    mV.validMailPassword = (/^[a-zA-Z0-9\s#?!@$ %^&*-]{0,}$/gm.test(emailPassword) === false) ? false : true
    mV.validMailPort = (/^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/.test(emailPort.toString()) === false) ? false : true
    mV.validWOHostname = (/^(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)$/.test(workforceoneHost) === false) ? false : true
    mV.validWOUsername = (/^[a-zA-Z0-9\.\@\s_-]{0,15}$/gm.test(workforceoneUsername) === false) ? false : true
    mV.validWOPassword = (/^[a-zA-Z0-9\s#?!@$ %^&*-]{0,}$/gm.test(workforceonePassword) === false) ? false : true
    mV.canSave = (mV.validFailedProcessingEmail && mV.validMailHostname && mV.validMailUsername && mV.validMailPassword && mV.validMailPort && mV.validWOHostname && mV.validWOUsername && mV.validWOPassword && true)
    setV(mV)
    return mV
  }

  const [buttonStyle, setButtonStyle] = useState("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")
  useEffect(() => {
    let mV: IValidation = {
      validFailedProcessingEmail: true,
      validMailHostname: true,
      validMailPassword: true,
      validMailPort: true,
      validMailUsername: true,
      validWOHostname: true,
      validWOPassword: true,
      validWOUsername: true,
      canSave: true
    }
    setV(mV)
    setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")
  }, [emailHost, emailPort, emailPassword, emailSSL, emailUsername, workforceoneHost, workforceonePassword, workforceoneUsername, failedProcessingEmail])


  useEffect(() => {
    (async () => {
      
      
      const [wusers, data]  = await Promise.all([
        API.GetUsers(), API.GetIntegrationTags()
      ])
      // console.log('wusers');
      // console.log(wusers);
      let index = data.findIndex((x:any) => x.id === id)
      if (index !== -1) {
        let settings = JSON.parse(data[index].settings)
        setEmailHost(settings?.emailHost ?? "")
        setEmailPort(settings?.emailPort ?? 0)
        setEmailUsername(settings?.emailUsername ?? "")
        setEmailPassword(settings?.emailPassword ?? "")
        setEmailSSL(settings?.emailSSL ?? false)
        setWorkforceoneHost(settings?.workforceoneHost ?? "")
        setWorkforceoneUsername(settings?.workforceoneUsername ?? "")
        setWorkforceonePassword(settings?.workforceonePassword ?? "")      
        setFailedProcessingEmail(settings?.failedProcessingEmail ?? "")  
        const workforceUsers = settings?.workforceOneUsers ?? []
        let clonedUsers = [...wusers[0]]
        for (const i in clonedUsers) {
          if (Object.prototype.hasOwnProperty.call(clonedUsers, i)) {
            let user = {...clonedUsers[i]};
            const wOI = workforceUsers.findIndex((x:any) => x[0] === user.id)
            if(wOI !== -1) {
              user.workforceOneId = workforceUsers[wOI][1]
              clonedUsers[i] = user
            }
          }
        }

        
        setUsers(clonedUsers)
      }
    })();
   }, [id, didSave])
   
   const OnSave = async () => {
    let v = validate()
    console.log(v)
    if (v.canSave === true) {      
      const workforceOneUsers = users.map(x => {
        if (x.workforceOneId === undefined || x.workforceOneId === null || x.workforceOneId === "") {
          return [x.id, "-1"]
        }
        return [x.id, x.workforceOneId]
      })
      //Validate
      // console.log(users)
      // console.log(workforceOneUsers);
  
      //Stringify
      let settings = JSON.stringify({
        emailHost: emailHost,
        emailPort: emailPort,
        emailUsername: emailUsername,
        emailPassword: emailPassword,
        emailSSL: emailSSL,
        workforceoneHost: workforceoneHost,
        workforceoneUsername: workforceoneUsername,
        workforceonePassword: workforceonePassword,
        workforceOneUsers: workforceOneUsers,
        failedProcessingEmail: failedProcessingEmail

      })
  
      //Update Serverside Settings
      let data = await API.PostWorkforceOneSettings(settings, id)

      //Update the Users
      let usersWithCustom: any = []
      // let postUsers = JSON.parse(JSON.stringify(users));
      usersWithCustom = users.map((user:any) => {user.custom1 = user.workforceOneId; return user}).map(x => {x.workforceOneId = undefined; x.metaAddUser = undefined; x.metaError = undefined; x.firstName = ""; x.lastName = ""; x.custom2 = ""; x.custom3 = ""; return x;});
      // console.log('postUsers');
      // console.log(postUsers);
      // console.log('usersWithCustom');
      // console.log(usersWithCustom);
      await API.PostUsers2(usersWithCustom);
      setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-green-400 rounded shadow-xl")
      console.log(data)
      setDidSave(!didSave)
      } else {
        setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-red-400 rounded shadow-xl")
      }
   }

   const handleWorkforceOneId = (i: number, id: string) => {
     setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")
    let items = [...users];
    let item = {...items[i]};
    item.workforceOneId = id;
    items[i] = item;
    setUsers(items)
   }

  return (
    <div className="h-auto">
      <h1 className="text-2xl font-roboto mb-6">Workforce One</h1>
      <div className="grid gap-10 grid-row-6 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 md:h-full font-roboto mb-3">
        <div>
          <h2>Manual Processing Settings</h2>
        </div>
        <div className="col-span-2">
          <label className="block text-gray-700 text-md font-roboto" htmlFor="hostnameemail1">
            Failed Processing Email
          </label>
          <input value={failedProcessingEmail} onChange={(x) => {setFailedProcessingEmail(x.target.value); setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")}} className={`shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="hostnamemail3" type="text" placeholder="test@example.com"/>
          <p className={`${v.validFailedProcessingEmail ? "opacity-0" : ""} text-sm text-red-500 pl-1 mb-4`}>Please enter a valid processing email</p>
        </div>
          <div>
            <h2>User Configuration</h2>
          </div>
          <div className="col-span-1 grid gap-5 grid-cols-1 ">
            <p className="col-span-2">Please enter the corresponding Workforce One Consultant Id for each of the users <span className="text-red-600">(required)</span></p>
            {users.map((item, index) => 
              (<div key={index} className=" col-span-2 flex flex-col text-md font-roboto ">
                <label className="text-gray-700 " htmlFor="hostnameemail2">
                  {item.displayname}
                </label>
                <input value={item.workforceOneId} onChange={(x) => handleWorkforceOneId(index, x.target.value)} className={`shadow appearance-none border rounded w-auto py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="hostnamemail4" type="number" placeholder="Workforce One Id"/>
              </div>
            ))}
          </div>
      </div>
      <div className="flex justify-left">
            <div className="h-10"></div>
            <button className={buttonStyle} onClick={()=>OnSave()}>Save</button>
      </div>
      <div className="grid gap-10 grid-row-6 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 md:h-full font-roboto mt-20 p-10 border-2 border-red-600 rounded mr-4">      
        <div className="col-span-3 flex flex-col">
          <h1 className="text-2xl font-roboto text-red-500 font-black ">Danger Zone <span className="text-sm">(Use at own risk)</span></h1> 
          <p className="text-sm font-roboto text-red-500">These settings are very important for the continued use of this service. They are highly technical and shouldn't be touched by anyone who doesn't understand the system.</p>
        </div>

        <div>
          <h2 className="text-red-500 font-bold">Mail Settings</h2>
        </div>
        <div className="col-span-2">
            <label className="block  text-md font-roboto mb-2" htmlFor="hostnameemail3">
            Hostname (ie; smtp.gmail.com)
            </label>
            <input value={emailHost} onChange={(x) => {setEmailHost(x.target.value); setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")}} className={`shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} id="hostnamemail5" type="text" placeholder="smtp.gmail.com"/>
            <p className={`${v.validMailHostname ? "opacity-0" : ""} text-sm text-red-500 pl-1 mb-4`}>Please enter a valid hostname</p>

            <label className="block  text-md font-roboto mb-2" htmlFor="port">
            Port number (ie; 465, 587, 25)
            </label>
            <input value={emailPort!} onChange={(x) => {setEmailPort(x.target.valueAsNumber); setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")}} className={`shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} id="port" type="number" placeholder="587"/>
            <p className={`${v.validMailPort ? "opacity-0" : ""} text-sm text-red-500 pl-1 mb-4`}>Please enter a valid port</p>

            <label className="block text-md font-roboto mb-2" htmlFor="username">
              Username
            </label>
            <input value={emailUsername} onChange={(x) => {setEmailUsername(x.target.value); setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")}} className={`shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} id="username" type="text" placeholder="username"/>
            <p className={`${v.validMailUsername ? "opacity-0" : ""} text-sm text-red-500 pl-1 mb-4`}>Please enter a valid username</p>

            <label className="block text-md font-roboto mb-2" htmlFor="password">
              Password
            </label>
            <input value={emailPassword} onChange={(x) => {setEmailPassword(x.target.value); setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")}} className={`shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`} id="password" type="password" placeholder="******************"/>
            <p className={`${v.validMailPassword ? "opacity-0" : ""} text-sm text-red-500 pl-1 mb-4`}>Please enter a valid password</p>

            <label className="block" htmlFor="flexCheckDefault">
              Use SSL
            </label>
            <input checked={emailSSL} onChange={(x) => {setEmailSSL(x.target.checked); setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")}}  type="checkbox" id="flexCheckDefault"/>
            <p className={`opacity-0 text-sm text-red-500 pl-1 mb-4`}>Test</p>
        </div>
        <div>
          <h2 className="text-red-500 font-bold">Workforce One Settings</h2>
        </div>
          <div className="col-span-2">
            <label className="block text-md font-roboto mb-2" htmlFor="hostname">
            Hostname (ie; test.workforceone.com.au)
            </label>
            <input value={workforceoneHost} onChange={(x) => {setWorkforceoneHost(x.target.value); setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")}} className={`shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="hostname6" type="text" placeholder="test.workforceone.com.au"/>
            <p className={`${v.validWOHostname ? "opacity-0" : ""} text-sm text-red-500 pl-1 mb-4`}>Please enter a valid hostname</p>
            <label className="block  text-md font-roboto mb-2" htmlFor="username">
              Username
            </label>
            <input value={workforceoneUsername} onChange={(x) => {setWorkforceoneUsername(x.target.value); setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")}} className={`shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="username1" type="text" placeholder="username"/>
            <p className={`${v.validWOUsername ? "opacity-0" : ""} text-sm text-red-500 pl-1 mb-4`}>Please enter a valid username</p>
            <label className="block text-md font-roboto mb-2" htmlFor="password">
              Password
            </label>
            <input value={workforceonePassword} onChange={(x) => {setWorkforceonePassword(x.target.value); setButtonStyle("mt-10 w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl")}} className={`shadow appearance-none border rounded w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`} id="password1" type="password" placeholder="******************"/>
            <p className={`${v.validWOPassword ? "opacity-0" : ""} text-sm text-red-500 pl-1 mb-4`}>Please enter a valid password</p>
          </div>
          <div className="h-1"></div>
        </div>
        <div className="block col-span-2"> </div>
        <div className="flex justify-left ">
            <div className="h-10"></div>
            
            <button className={buttonStyle} onClick={()=>OnSave()}>Save</button>
        </div>
    </div>
  );
}

export default UploadWorkforceOneSettings;
