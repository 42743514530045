import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import API from "../../helpers/api"
import { hashPassword } from "../../helpers/security"
import { ActionState, DisplaySideActionState, ListItemIdState,  UpdateUserState,  UserDataState } from "../../state/atoms"
import { IUser } from "../../state/interfaces"
import Dropdown from "../Dropbox"

export const SideActionContentUser = () => {

    interface IUserValidation {
        validDisplayName: boolean
        validEmail: boolean,
        validFirstname: boolean,
        validLastname:boolean,
        validUsername: boolean,
        validPassword: boolean,
        canSubmit: boolean
    }
    const [action, setAction] = useRecoilState(ActionState)
    const [itemId, setItemId] = useRecoilState(ListItemIdState)
    const [userData, SetUserData] = useRecoilState(UserDataState)
    const [UpdateUserDisplay, SetUpdateUserDisplay] = useRecoilState(UpdateUserState)
    const [displaySideAction, setDisplaySideAction] = useRecoilState(DisplaySideActionState)
    const [user, setUser] = useState<IUser>()
    const [vUser, setVUser] = useState<IUserValidation>(
        {
            validDisplayName: true,
            validEmail: true,
            validFirstname: true,
            validLastname: true,
            validPassword: true,
            validUsername: true,
            canSubmit: true
        } as IUserValidation
    )

    const validate = () => {
        let mVUser: IUserValidation = {
            validDisplayName: false,
            validEmail: false,
            validFirstname: false,
            validLastname: false,
            validPassword: false,
            validUsername: false,
            canSubmit: false
        }
        if (user != undefined || user != null) {
            if (/^[a-zA-Z0-9\s]{3,15}$/gm.test(user?.displayname || "") === false) {
                setVUser(prevState => { 
                    const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "validDisplayName": false });return { ...prevState, ...newState };})
            } else{
                mVUser.validDisplayName = true
            }
            if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(user?.email || "") === false) {
                setVUser(prevState => { 
                    const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "validEmail": false });return { ...prevState, ...newState };})
            } else{
                mVUser.validEmail = true
            }
            if (new RegExp("^[a-zA-Z\s]{0,15}$").test(user?.firstName || "") === false) {
                setVUser(prevState => { 
                    const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "validFirstname": false });return { ...prevState, ...newState };})
            } else{
                mVUser.validFirstname = true
            }
            if (new RegExp("^[a-zA-Z\s]{0,15}$").test(user?.lastName || "") === false) {
                setVUser(prevState => { 
                    const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "validLastname": false });return { ...prevState, ...newState };})
            } else{
                mVUser.validLastname = true
            }
            if (new RegExp("^[a-z0-9_-]{3,15}$").test(user?.username || "") === false) {
                setVUser(prevState => { 
                    const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "validUsername": false });return { ...prevState, ...newState };})
            } else{
                mVUser.validUsername = true
            }
            if (action === "Create") {
                if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$").test(user?.password || "") === false) {
                    setVUser(prevState => { 
                        const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "validPassword": false });return { ...prevState, ...newState };})
                } else{
                    mVUser.validPassword = true
                }
            } else {
                if (user.password === "") {
                    mVUser.validPassword = true
                } else {
                    if (new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$").test(user?.password || "") === false) {
                        setVUser(prevState => { 
                            const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "validPassword": false });return { ...prevState, ...newState };})
                    } else{
                        mVUser.validPassword = true
                    }
                }
            }
    
            if(true && mVUser.validDisplayName && mVUser.validEmail && mVUser.validFirstname && mVUser.validLastname && mVUser.validPassword && mVUser.validUsername) {
                mVUser.canSubmit = true
            }
    
            setVUser(mVUser)
        }
        return mVUser
    }

    useEffect(() => {
        let ie = {
            validDisplayName: true,
            validEmail: true,
            validFirstname: true,
            validLastname: true,
            validPassword: true,
            validUsername: true,
            canSubmit: true
        } as IUserValidation
        setVUser(ie)
    }, [user])


    useEffect(() => {
        console.log(itemId)
        console.log(userData)
        var empty: IUser = {
            email: "",
            phone: "",
            password: "",
            id: 0,
            username: "",
            displayname: "",
            firstName: "",
            lastName: "",
            role: "User"
        }
        if (action === "Create") {
            setUser(empty);
        }

        if (action === "Edit") {
            let raw = userData.find(x => x.id as unknown as string === itemId)
            if (raw != null) {
                let muser = JSON.parse(JSON.stringify(raw))
                if (muser.lastName == null)  {muser.lastName = ""}
                if (muser.firstName == null)  {muser.firstName = ""}
                setUser(muser);
            }
            
        }

    }, [action, itemId, displaySideAction])

    const handleCreate = async () => {
        let v = validate()
        if(v.canSubmit) {
            console.log("Create")
            console.log(user)
            let muser = JSON.parse(JSON.stringify(user))
            muser.password = await hashPassword(muser.password)
            console.log(muser.password)
            await API.PostUsers(muser)
            SetUpdateUserDisplay(!UpdateUserDisplay)
            setDisplaySideAction(false)
        }
    }

    const handleEdit = async () => {
        let v = validate()
        if (v.canSubmit) {
            console.log("Edit")
            console.log(user)
            let muser = JSON.parse(JSON.stringify(user))
            if (muser.password != "") {
                muser.password = await hashPassword(muser.password)
            }
            await API.PostUsers(muser)
            console.log(muser.password)
            SetUpdateUserDisplay(!UpdateUserDisplay)
            setDisplaySideAction(false)
        }
    }

    return (
        <>
            <div className="flex flex-row">
                <input required placeholder="Enter Name"  onChange={x => {setUser(prevState => { const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "displayname": x.target.value });return { ...prevState, ...newState };})}} pattern="^[a-zA-Z0-9\s]{3,15}$" type="text" value={user?.displayname}  className={`text-[#3F3C3C] font-medium w-56 text-3xl appearance-none bg-transparent outline-none border-b-2  placeholder-[#c6c6c6]`}/>
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 self-start w-5 ${action === "Create" ? 'hidden' : 'block'}`} viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
            </div>
            <p className={`${vUser?.validDisplayName ? "opacity-0" : ""} text-sm text-red-500 pl-1 mb-4`}>Please enter a valid display name</p>
            <div className="flex flex-col gap-y-2">
                {/* <div className="flex flex-row">
                    <input className={`mr-3 form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 shadow-inner bg-[#F1F1F1] checked:bg-[#999999] checked:border-4 checked:border-invisible checked: checked:shadow-none focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left  cursor-pointer`} type="radio" name="flexRadioDefault" id="flexRadioDefault20" />
                    <label>Block</label>
                </div> */}
                {/* <div className="flex flex-col">
                    <label>Display Name</label>
                    <input type="text"  className="px-3 bg-white w-40 border border-[#dad9d9] rounded-xl h-7 shadow-inner lg:w-44"/>
                </div> */}

                <div className="flex flex-col">
                    <label>Email</label>
                    <input required onChange={x => {setUser(prevState => { const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "email": x.target.value });return { ...prevState, ...newState };})}} type="email" value={user?.email} className=" px-3 bg-white w-40 border border-[#dad9d9] rounded-xl h-7 shadow-inner lg:w-44"/>
                    <p className={`${vUser?.validEmail ? "opacity-0" : ""} text-sm text-red-500 pl-1`}>Please enter a valid email</p>
                </div>
                <div className="flex flex-col">
                    <label>First Name</label>
                    <input  pattern="^[a-zA-Z\s]{0,15}$" onChange={x => {setUser(prevState => { const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "firstName": x.target.value });return { ...prevState, ...newState };})}} type="text" value={user?.firstName} className=" px-3 bg-white w-40 border border-[#dad9d9] rounded-xl h-7 shadow-inner lg:w-44"/>
                    <p className={`${vUser?.validFirstname ? "opacity-0" : ""} text-sm text-red-500 pl-1`}>Your name cannot contain numbers</p>
                </div>
                <div className="flex flex-col">
                    <label>Last Name</label>
                    <input pattern="^[a-zA-Z\s]{0,15}$" onChange={x => {setUser(prevState => { const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "lastName": x.target.value });return { ...prevState, ...newState };})}} type="text" value={user?.lastName} className="px-3 bg-white w-40 border border-[#dad9d9] rounded-xl h-7 shadow-inner lg:w-44"/>
                    <p className={`${vUser?.validLastname ? "opacity-0" : ""} text-sm text-red-500 pl-1`}>Your name cannot contain numbers</p>
                </div>
                <div className="flex flex-col">
                    <label>Username</label>
                    <input title="Alphanumeric string that may include _ and - having a length of 3 to 16 characters." required pattern="^[a-z0-9_-]{3,15}$" onChange={x => {setUser(prevState => { const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "username": x.target.value });return { ...prevState, ...newState };})}} value={user?.username} disabled={action !== "Create"} type="text"  className={`px-3 ${ action === "Create" ? "bg-white" : "bg-slate-200"} w-40 border border-[#dad9d9] rounded-xl h-7 shadow-inner lg:w-44`}/>
                    <p className={`${vUser?.validUsername ? "opacity-0" : ""} text-sm text-red-500 pl-1`}>Username may include _ and - having a length of 3 to 16 characters.</p>
                </div>
                <div className="flex flex-col">
                    <label>Password</label>
                    <input title="Minimum eight characters, at least one upper case English letter, one lower case English letter, one number and one special character" required={action === "Create"} className={`px-3 placeholder:text-slate-400 bg-white w-40 border border-[#dad9d9] rounded-xl h-7 shadow-inner lg:w-44`} pattern='^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$' onChange={x => {setUser(prevState => { const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "password": x.target.value });return { ...prevState, ...newState };})}} value={user?.password} type="password" placeholder={`${action === "Create" ? "" : "··········"}`}  />
                    <p className={`${vUser?.validPassword ? "opacity-0" : ""} text-sm text-red-500 pl-1`}>8 char long, 1 Upper, 1 lower, 1 number and 1 special</p>
                </div>
                <div className="flex flex-col">
                    <label>Role</label>
                    {/* <input disabled={action !== "Create"} type="text"  className={`px-3 ${ action === "Create" ? "bg-white" : "bg-slate-200"} bg-white w-40 rounded-xl h-7 shadow-inner lg:w-44`}/> */}
                    <Dropdown value={user?.role ?? "User"} handler={x => {setUser(prevState => { const newState = Object.assign(JSON.parse(JSON.stringify(user)), { "role": x });return { ...prevState, ...newState };})}}/>
                </div>
                <button disabled={!vUser?.canSubmit} onClick={action === "Create" ? handleCreate : handleEdit} className="bg-white text-[#00A0F8] px-6 rounded-lg drop-shadow mt-6 w-20 active:drop-shadow-none active:text-slate-400 active:border-opacity-100">Save</button>
            </div>
        </>
    )
}