// import React, {useState} from 'react';
import {
  useRecoilState,
} from 'recoil';
import {integrationState} from "../state/atoms"
import "@fontsource/roboto";
import API from "../helpers/api";
import {useEffect, useState} from 'react';
import UploadWorkforceOneSettings from "./UploadWorkforceOneSettings"
import UploadIntegrationData from './UploadIntegrationData';

function DisplayIntegrations(props) {
  const [integrations, setIntegrations] = useRecoilState(integrationState);
  const [settingsIsDisplayed, setSettingsIsDisplayed] = useState(false);
  const [listIsDisplayed, setListIsDisplayed] = useState(true);
  const [selectedID, setSelectedId] = useState(-1);


  useEffect(() => {
    (async () => {
      let data  = await API.GetIntegrationTags();
      let mappedData = data.map(x => {return {id: x.id, name: x.name, type: x.type, settings: x.settings, showContext: false}})
      setIntegrations(mappedData)
      console.log(data);
    })();
  }, [setIntegrations])

  const onDisplaySettings = () => {
    let i = integrations.findIndex(x => x.id === selectedID)
    switch (integrations[i].type) {
      case 1:
        return <UploadWorkforceOneSettings id={selectedID} />
    
      case 0:
        return <UploadIntegrationData id={selectedID}/>
      default:
        return <div></div>
    }
  }

  const typeInformation = (type) => {
    switch (type) {
      case 1:
        return "Workforce One Service"
    
      case 0:
        return "Storage Service"
      default:
        return "Unknown"
    }
  }

  const onBackFromManage = () => {
    setSettingsIsDisplayed(false)
    setListIsDisplayed(true)
  }

  const onManage = (id) => {
    setSelectedId(id)
    setListIsDisplayed(false)
    setSettingsIsDisplayed(true)
    let i = integrations.findIndex(x => x.id === id)
    let newIntegrations = JSON.parse(JSON.stringify(integrations))
    newIntegrations[i].showContext = false
    setIntegrations(newIntegrations)
  }

  const onContext = (id, state) => {
    let i = integrations.findIndex(x => x.id === id)
    
    let newIntegrations = JSON.parse(JSON.stringify(integrations))
    newIntegrations[i].showContext = state
    setIntegrations(newIntegrations)
  }
  
  return (
    <div className="h-auto ml-9 mt-9">
      {settingsIsDisplayed && <button onClick={onBackFromManage} className='float-right font-bold text-blue-500 pt-1 mr-4 flex flex-row'>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
        </svg>
        Back
      </button>}
      {listIsDisplayed && <h1 className="text-2xl font-roboto mb-6">Manage Integrations</h1>}
      {listIsDisplayed && integrations.map((item, index) => {
        return (
          <div className="h-10 shadow-top pt-2 w-full flex" key={index}>
            <div className='flex flex-grow' onClick={()=>onManage(item.id)}>
              <h1 className="font-roboto text-s font-bold">{item.name}</h1>
              <h1 className="font-roboto text-s italic ml-2">{typeInformation(item.type)}</h1>
            </div>
            {/* <div className='px-80' onClick={()=>onManage(item.id)}></div> */}
            <div className="relative">
              <svg onClick={() => onContext(item.id, true)} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
              </svg>
              {item.showContext === true &&
              <div onMouseLeave={() => onContext(item.id, false)} className="absolute z-10 -bottom-9 right-0 bg-white shadow-md h-10 w-20 flex flex-col text-center py-2 rounded">
                <p onClick={()=>onManage(item.id)} className="">Manage</p>
                {/* <p onClick={()=>onDelete(item.id)} className="text-red-600">Delete</p> */}
              </div>
              }
            </div>
          </div>
        )})}
        {settingsIsDisplayed && onDisplaySettings()}
    </div>
  );
}

export default DisplayIntegrations;
