// import React, {useState} from 'react';
import "@fontsource/roboto";

function DisplaySupportInformation() {
  return (
    <div className="h-auto">
      <h1 className="text-2xl font-roboto mb-2">Support</h1>

      <p>If there is an issue please dont hesitate to contact us on <a className="text-blue-800" href="tel:0863944349">08 6394 4349</a> or email us at <a className="text-blue-800" href = "mailto: itservice@biz3265.com.au">itservice@biz3265.com.au</a> </p>
    </div>
    
  );
}

export default DisplaySupportInformation;
