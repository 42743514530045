import {
    SET_TEMPLATEDATA
  } from '../constants/ActionTypes'
  
  const initialState = [
    {
      templateData: []
    }
  ]
  
  export default function templateData(state = initialState, action: any) {
    switch (action.type) {
      case SET_TEMPLATEDATA:
        return [
          {
            templateData: action.templateData
          }
        ]
      default:
        return state
    }
  }
  