import {atom} from 'recoil';
import { IUser } from './interfaces';
interface IDataItem {
    id: number,
    name: string,
    type: number,
    settings: string
    showContext: boolean
}

const localStorageEffect = key => ({setSelf, onSet}) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
        isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
};

export const integrationState = atom({
    key: 'integrationState', // unique ID (with respect to other atoms/selectors)
    default: Array<IDataItem>(), // default value (aka initial value)
    effects: [
        localStorageEffect('integrationState'),
        ]
});

export const templateState = atom({
    key: 'templateState', // unique ID (with respect to other atoms/selectors)
    default: Array<any>(), // default value (aka initial value)
    effects: [
        localStorageEffect('templateState'),
        ]
});
export const templateRenderState = atom({
    key: 'templateRenderState', // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
    effects: [
        localStorageEffect('templateState'),
        ]
});

export const UserDataState = atom({
    key: 'UserDataState', // unique ID (with respect to other atoms/selectors)
    default: Array<IUser>(), // default value (aka initial value)
});

export const ActionState = atom({
    key: 'ActionState', // unique ID (with respect to other atoms/selectors)
    default: "Create", // default value (aka initial value)
});

export const ListItemIdState = atom({
    key: 'ListItemIdState', // unique ID (with respect to other atoms/selectors)
    default: "", // default value (aka initial value)
});

export const ListState = atom({
    key: 'ListState', // unique ID (with respect to other atoms/selectors)
    default: Array<string>(), // default value (aka initial value)
});

export const DisplaySideActionState = atom({
    key: 'DisplaySideActionState', // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
});
export const UpdateUserState = atom({
    key: 'UpdateUserState', // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
});

