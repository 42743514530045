import React, {useState, useEffect} from 'react';
import "@fontsource/roboto";
import API from '../helpers/api';
import {store} from '../index';
import { setAppContentState, setSignState } from '../redux/actions'

function TopNavigationBar() {
  interface IUser {
    id: number,   
    username: string,
    firstName: string,
    lastName: string,
    displayname: string,
    email: string,
    role: string,
    password: string
  }

  const [showOptions, setShowOptions] = useState(false)
  const [user, setUser] = useState<IUser>()
  
  useEffect(() => {
    (async () => {
      let data  = await API.GetUser();
      setUser(data[0])
    })();
   }, [])

  const hide = (e) => {
    if(e && e.relatedTarget){
      e.relatedTarget.click();
    }
    setShowOptions(false);
  }

  const doSomething = (e) => {
    store.dispatch(setAppContentState(e));
  }

  const handleSignOut = () => {
      API.token = ""
      localStorage.removeItem('token');
      store.dispatch(setSignState(false))
  }

  return (
    <div className="h-11 bg-white border-b-2 border-defaultWhite grid grid-cols-7">
      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
      <div className="col-span-5 text-left float-left w-full">
        {/* <input className="focus:outline-none w-full py-2 px-4 text-gray-400" placeholder="Search" ></input> */}
        {/* <p className="focus:outline-none w-full py-2 px-4 text-gray-400">Search <i>(soon)</i></p> */}
      </div>
      <div className="col-span-2">
        <div className="w-30 float-right">
          <button onBlur={() => hide} onClick={() => setShowOptions(!showOptions)} type="button" className="inline-flex justify-center w-full py-3 text-sm font-medium text-gray-400 outline-none" id="menu-button" aria-expanded="true" aria-haspopup="true">
            {user?.email ?? "My Account"}
            {/* <!-- Heroicon name: solid/chevron-down --> */}
            <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        {showOptions &&
        <div onMouseLeave={() => setShowOptions(!showOptions)} className="origin-top-right absolute top-11 right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1} >
          <div className="py-1" role="none">
            {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
            {/* <div className="text-gray-700 block px-4 py-2 text-sm" id="menu-item-0">Account settings <i>(soon)</i></div> */}
            <button onClick={()=>doSomething(12)} className="text-gray-700 block px-4 py-2 text-sm outline-none" id="menu-item-1">Support</button>
            <button onClick={()=>doSomething(13)} className="text-gray-700 block px-4 py-2 text-sm outline-none" id="menu-item-2">Terms and Conditions</button>
            <div onClick={()=>handleSignOut()} className="text-gray-700 block px-4 py-2 text-sm" id="menu-item-0">Sign Out</div>
            {/* <form method="POST" action="#" role="none">
              <button type="submit" className="text-gray-700 block w-full text-left px-4 py-2 text-sm" role="menuitem" tabIndex={-1}  id="menu-item-3">
                Sign out
              </button>
            </form> */}
          </div>
        </div>
        }
      </div>
    </div>
  );
}

export default TopNavigationBar;
