export const SET_ERROR: string = 'SET_ERROR'
export const SET_INTEGRATIONDATA: string = 'SET_INTEGRATIONDATA'
export const SET_TEMPLATEDATA: string = 'SET_TEMPLATEDATA'
export const SET_USERDATA: string = 'SET_USERDATA'
export const SET_TEMPUSERDATA: string = 'SET_TEMPUSERDATA'
export const SET_APPCONTENTSTATE: string = 'SET_APPCONTENTSTATE'
export const SET_SIGNSTATE: string = 'SET_SIGNSTATE'
export const SET_MANAGEUSERSAVESTATE: string = 'SET_MANAGEUSERSAVESTATE'
export const SET_MANAGEINTEGRATIONTAGSAVESTATE: string = 'SET_MANAGEINTEGRATIONTAGSAVESTATE'
export const SET_TEMPINTEGRATIONTAGDATA: string = 'SET_TEMPINTEGRATIONTAGDATA'
export const SET_INTEGRATIONTAGDATA: string = 'SET_INTEGRATIONTAGDATA'