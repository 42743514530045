import "@fontsource/roboto";
import {useEffect, useState} from 'react';
import { setError, setTemplateDataDisplay } from '../redux/actions'
import {store} from '../index'
import API from '../helpers/api'
import { FileUploader } from "react-drag-drop-files";
import {
  useRecoilState
} from 'recoil';
import {templateRenderState, templateState} from "../state/atoms"

function UploadTemplate() {
  const [selectedFiles, setSelectedFiles] = useState<FileList>();
  const [uploadButtonState, setUploadButtonState] = useState(0);
  const [uploadState, setUploadState] = useState(false);
  const fileTypes = ["pdf"];
  const [templateRenderRequired, setTemmplateRenderRequired] = useRecoilState(templateRenderState);


  useEffect(() => {
    if(uploadButtonState === 3) {
      (async () => {
        var myerror = Error();
        myerror.name = ""
        myerror.message = ``
        store.dispatch(setError(myerror));
        if (selectedFiles !== undefined) {
          for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            Object.defineProperty(file, 'name', {
              writable: true,
              value: file.name.slice(0, -4)
            });
            await API.PostTemplate(file)
          }
        }
        setTemmplateRenderRequired(!templateRenderRequired)
        
        if (store.getState().admin[0].myerror.name === "") {
          store.dispatch(setTemplateDataDisplay(await API.GetTemplates()))
          setUploadButtonState(4);
        }
        else {
          myerror = Error();
          myerror.name = "Network Error"
          myerror.message = `There was a problem with the submission. The form has been reset. Please resubmit to resolve the issue`
          store.dispatch(setError(myerror));
          setUploadButtonState(1);
        }
      })()
    }
  }, [uploadButtonState, selectedFiles])


  const getUploadButtonStyle = (state: number): string => {
    switch (state) {
      case 0:
        return "w-full px-4 py-2 text-white bg-defaultGray rounded shadow-xl"
      case 1:
        return "w-full px-4 py-2 text-white bg-red-600 rounded animate-wiggle shadow-xl"
      case 2:
        return "w-full px-4 py-2 text-white bg-primary rounded shadow-xl"
      case 3:
        return "w-full px-4 py-2 text-white bg-primary rounded shadow-xl"
      case 4:
        return "w-full px-4 py-2 text-white bg-green-400 rounded shadow-xl"
      default:
        return "w-full px-4 py-2 text-white bg-defaultGray rounded shadow-xl"
    }
  }

  const getUploadButtonText = (state: number): string => {
    switch (state) {
      case 0:
        //Init
        return "Upload"
      case 1:
        //Invalid
        return "Try again"
      case 2:
        //Valid
        return "Upload"
      case 3:
        //Valid and waiting for call to finish
        return "Upload"
      case 4:
        //Uploaded
        return "Uploaded"
      default:
        return "Upload"
    }
  }

  const onFileChange = (files: FileList) => {
    console.log(files)
    setUploadState(false);
    setUploadButtonState(0);
    // setClickCount(0);
    let count: number = 0;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      switch (true) {
        case file.name.length > 256:
          console.log("1")
          var myerror = Error();
          myerror.name = "File Attachment Error"
          myerror.message = `There was a problem with the attachment you submitted. Please check the length of the name`
          store.dispatch(setError(myerror));
          break;
        case file.name.length === 0:
          console.log("2")
          myerror = Error();
          myerror.name = "File Attachment Error"
          myerror.message = `There was a problem with the attachment you submitted. Please check the length of the name`
          store.dispatch(setError(myerror));
          break;
        case !(/.*\.pdf$/.test(file.name)):
          console.log("3")
          myerror = Error();
          myerror.name = "File Attachment Error"
          myerror.message = `There was a problem with the attachment you submitted. Please check the filetype`
          store.dispatch(setError(myerror));
          break;
        case /.*\.pdf$/.test(file.name):
          console.log("5")
          if (file.size <= 0) {
            myerror = Error();
            myerror.name = "File Attachment Error"
            myerror.message = `There was a problem with the attachment you submitted. Please check the size of the file`
            store.dispatch(setError(myerror));
            setUploadButtonState(1);
          } else {
            count++
          }
          break;   
        default:
          console.log("4")
          myerror = Error();
          myerror.name = "File Attachment Error"
          myerror.message = `There was a problem with the attachment you submitted. Please contact IT`
          store.dispatch(setError(myerror));
          break;
      }
    }

    if(count === files.length) {
      setSelectedFiles(files);
      setUploadButtonState(2);
      setUploadState(true)
    } else {
      setUploadButtonState(1);
    }
  }

  const onFileUpload = async () => {
    if ((uploadState === true) && (uploadButtonState === 2)) {
      setUploadButtonState(3)
    } else {
      let myerror = Error();
          myerror.name = "File Attachment Error"
          myerror.message = `There was a problem with the attachment you submitted. Please check that a file is attached`
          store.dispatch(setError(myerror));
    }
    
  }

  const handleFileChangeStyle = () => {
    if(selectedFiles !== undefined) {
      return (
        <div className="max-w-2xl bg-green-50">
          <FileUploader hoverTitle=" " name="file" types={fileTypes} multiple={true} fileOrFiles={[]}>
            <div className="">
              <div className="flex items-center justify-center w-full">
                  <label
                      className="flex flex-col w-full h-32 border-2 border-green-200 border-dashed hover:bg-green-100 hover:border-green-300">
                      <div className="flex flex-col items-center justify-center pt-7">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                              fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                          </svg> */}
                          <svg className="w-8 h-8 text-green-400 group-hover:text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <g id="check_circle_outline_24px">
                            <path id="icon/action/check_circle_outline_24px" fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47998 2 2 6.48 2 12C2 17.52 6.47998 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58997 20 4 16.41 4 12C4 7.59 7.58997 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM10 14.17L16.59 7.58L18 9L10 17L6 13L7.41003 11.59L10 14.17Z" fill="currentColor" fillOpacity="0.54"/>
                            </g>
                          </svg>
                          <p className="pt-1 text-sm tracking-wider text-green-400 group-hover:text-gray-600">
                              Attached</p>
                      </div>
                  </label>
              </div>
            </div>
          </FileUploader>
          <div className="flex justify-center p-2 bg-white">
            <button disabled={uploadButtonState === 4} onClick={() => onFileUpload()} className={getUploadButtonStyle(uploadButtonState)}>{getUploadButtonText(uploadButtonState)}</button>
          </div>
        </div>
      )
    }

    if(selectedFiles === undefined) {
      return (
        <div className="max-w-2xl bg-gray-50">
          <FileUploader hoverTitle=" " handleChange={x => onFileChange(x)} name="file" types={fileTypes} multiple={true} fileOrFiles={[]}>
            <div className="">
              <div className="flex items-center justify-center w-full">
                <label
                    className="flex flex-col w-full h-32 border-2 border-gray-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                    <div className="flex flex-col items-center justify-center pt-7">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                            Click to Attach a PDF</p>
                    </div>
                </label>
              </div>
            </div>
          </FileUploader>
          <div className="flex justify-center p-2">
            <button disabled={uploadButtonState === 4} onClick={() => onFileUpload()} className={getUploadButtonStyle(uploadButtonState)}>{getUploadButtonText(uploadButtonState)}</button>
          </div>
        </div>
      )
    }

  }

  return (
    <div className="h-auto">
      <h1 className="text-2xl font-roboto mb-6">Upload Template</h1>
      <div className="grid gap-10 grid-row-6 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 md:h-full font-roboto">
        <div>
          <h2>Select Fillable PDF</h2>
        </div>
        <div className="col-span-2">
          {handleFileChangeStyle()}
        </div>
        {/* <div className="flex justify-left"> */}
        {/* <div className="h-10"></div> */}
        {/* <button onClick={() => onFileUpload()} className="px-4 py-2 text-white bg-primary rounded shadow-xl">{uploadState ? "Uploaded" : "Upload"}</button> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default UploadTemplate;
