import "@fontsource/roboto";
import Dropdown from './Dropdown';
import API from "../helpers/api";
import {useEffect, useState} from 'react';
import { setError, setIntegrationDataDisplay } from '../redux/actions'
import {store} from '../index'
import {integrationState} from "../state/atoms"
import Papa from 'papaparse';
import { FileUploader } from "react-drag-drop-files";
import {
  useRecoilState
} from 'recoil';

interface UploadIntegrationDataProps {
    id: number
}
const fileTypes = ["csv"];


function UploadIntegrationData({id} : UploadIntegrationDataProps) {
  interface IDataItem {
    id: number,
    name: string
  }
  interface IDataItems extends Array<IDataItem>{}


  const [selectedFile, setSelectedFile] = useState<File>(new File([],""));
  const [uploadButtonState, setUploadButtonState] = useState(0);
  const [uploadState, setUploadState] = useState(false);
  const [integrationData, setIntegrationData] = useState<IDataItems>([]);
  const [csvData, setCSVData] = useState<IDataItems>([]);
  const [existingIntegrationDropDown, setExistingIntegrationDropDown] = useState<IDataItem>({} as IDataItem);
  const [identifyingFieldDropDown, setIdentifyingFieldDropDown] = useState<IDataItem>({} as IDataItem);
  const [valueFieldDropDown, setValueFieldDropDown] = useState<IDataItem>({} as IDataItem);
  const [renderKey, setRenderKey] = useState(0);
  const [integrations, setIntegrations] = useRecoilState(integrationState); //eslint-disable-line



  useEffect(() => {
    (async () => {
      let data = integrations;
      console.log(`hello: ${id}`)
      setIntegrationData(data.filter(x => x.id === id))
    })();
   }, [id, integrations])

   useEffect(() => {
     if (uploadButtonState !== 4) {
      if((existingIntegrationDropDown.id === -1) || (identifyingFieldDropDown.id === -1) || (valueFieldDropDown.id === -1)) {
        setUploadButtonState(0)
      }
      else if ((existingIntegrationDropDown.id !== undefined) && (identifyingFieldDropDown.id !== undefined) && (valueFieldDropDown.id !== undefined)) {
        setUploadButtonState(2)
      }
      else {
        setUploadButtonState(0)
      }
     }

   }, [existingIntegrationDropDown, identifyingFieldDropDown, valueFieldDropDown, uploadButtonState])

   useEffect(()=> {
     if(uploadButtonState === 3) {
       (async () => {
        var myerror = Error();
        myerror.name = ""
        myerror.message = ``
        store.dispatch(setError(myerror));
        await API.PostIntegrationData(selectedFile, existingIntegrationDropDown.id, identifyingFieldDropDown.name, valueFieldDropDown.name)
        if (store.getState().admin[0].myerror.name === "") {
          store.dispatch(setIntegrationDataDisplay(await API.GetAllIntegrationData()))
          setUploadButtonState(4)
          setExistingIntegrationDropDown({id: -1, name:""} as IDataItem);
          setIdentifyingFieldDropDown({id: -1, name:""} as IDataItem);
          setValueFieldDropDown({id: -1, name:""} as IDataItem);
          setRenderKey(renderKey+1);
          setSelectedFile(new File([],""));
          setCSVData([]);
        } else {
          // setUploadButtonState(
          myerror = Error();
          myerror.name = "Network Error"
          myerror.message = `There was a problem with the submission. The form has been reset. Please resubmit to resolve the issue`
          store.dispatch(setError(myerror));
          setUploadButtonState(1);
        }
        
       })()
      
     }
   }, [uploadButtonState, selectedFile, existingIntegrationDropDown, identifyingFieldDropDown, valueFieldDropDown, renderKey])



   const getUploadButtonStyle = (state: number): string => {
    switch (state) {
      case 0:
        return "w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl"
      case 1:
        return "w-36 px-4 py-2 text-white bg-red-600 rounded animate-wiggle shadow-xl"
      case 2:
        return "w-36 px-4 py-2 text-white bg-primary rounded shadow-xl"
      case 3:
        return "w-36 px-4 py-2 text-white bg-primary rounded shadow-xl"
      case 4:
        return "w-36 px-4 py-2 text-white bg-green-400 rounded shadow-xl"
      default:
        return "w-36 px-4 py-2 text-white bg-defaultGray rounded shadow-xl"
    }
  }

  const getUploadButtonText = (state: number): string => {
    switch (state) {
      case 0:
        //Init
        return "Upload"
      case 1:
        //Invalid
        return "Try again"
      case 2:
        //Valid
        return "Upload"
      case 3:
        //Valid and waiting for call to finish
        return "Upload"
      case 4:
        //Uploaded
        return "Uploaded"
      default:
        return "Upload"
    }
  }

  const handleFileChangeStyle = () => {
 
    if(selectedFile.name !== "") {
      return(

        <FileUploader hoverTitle=" " handleChange={x => onFileChange(x)} name="file" types={fileTypes} multiple={false}>
                <div className="max-w-2xl bg-green-50">
                  <div className="">
                    <div className="flex items-center justify-center w-full">
                      <label
                        className="flex flex-col w-full h-32 border-2 border-green-200 border-dashed hover:bg-green-100 hover:border-green-300">
                        <div className="flex flex-col items-center justify-center pt-7">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg> */}
                            <svg className="w-8 h-8 text-green-400 group-hover:text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <g id="check_circle_outline_24px">
                              <path id="icon/action/check_circle_outline_24px" fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47998 2 2 6.48 2 12C2 17.52 6.47998 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58997 20 4 16.41 4 12C4 7.59 7.58997 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM10 14.17L16.59 7.58L18 9L10 17L6 13L7.41003 11.59L10 14.17Z" fill="currentColor" fillOpacity="0.54"/>
                              </g>
                            </svg>
                            <p className="pt-1 text-sm tracking-wider text-green-400 group-hover:text-gray-600">
                                Attached</p>
                        </div>
                        {/* <input onDrop={(e) => onFileChange(e)} onChange={(e) => onFileChange(e)} accept="text/csv" type="file" className="opacity-0" /> */}
                    </label>
                </div>
              </div>
              {/* <div className="flex justify-center p-2">
                <button className="w-full px-4 py-2 text-white bg-defaultGray rounded shadow-xl">Create</button>
              </div> */}
            </div>
        </FileUploader>
        )
    }

    if(selectedFile.name === "") {
      return (
        <FileUploader hoverTitle=" " handleChange={x => onFileChange(x)} name="file" types={fileTypes} multiple={false}>
            <div className="max-w-2xl bg-gray-50">
            <div className="">
              <div className="flex items-center justify-center w-full">
                  <label
                      className="flex flex-col w-full h-32 border-2 border-gray-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                      <div className="flex flex-col items-center justify-center pt-7">
                          <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                              fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                          </svg>
                          <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                              Click to Attach a CSV</p>
                      </div>
                  </label>
              </div>
            </div>
          </div>
        </FileUploader>
      )
    }

  }

  const onFileChange = file => {
    setExistingIntegrationDropDown({id: -1, name:""} as IDataItem);
    setIdentifyingFieldDropDown({id: -1, name:""} as IDataItem);
    setValueFieldDropDown({id: -1, name:""} as IDataItem);
    setUploadState(false);
    setUploadButtonState(0);
    setRenderKey(renderKey+1);
    // setClickCount(0);

    switch (true) {
      case file.name.length > 256:
        console.log("1")
        var myerror = Error();
        myerror.name = "File Attachment Error"
        myerror.message = `There was a problem with the attachment you submitted. Please check the length of the name`
        store.dispatch(setError(myerror));
        setUploadButtonState(1);
        break;
      case file.name.length === 0:
        console.log("2")
        myerror = Error();
        myerror.name = "File Attachment Error"
        myerror.message = `There was a problem with the attachment you submitted. Please check the length of the name`
        store.dispatch(setError(myerror));
        setUploadButtonState(1);
        break;
      case !(/.*\.csv$/.test(file.name)):
        console.log("3")
        myerror = Error();
        myerror.name = "File Attachment Error"
        myerror.message = `There was a problem with the attachment you submitted. Please check the filetype`
        store.dispatch(setError(myerror));
        setUploadButtonState(1);
        break;
      case /.*\.csv$/.test(file.name):
        console.log("3")
        if (file.size <= 0) {
          myerror = Error();
          myerror.name = "File Attachment Error"
          myerror.message = `There was a problem with the attachment you submitted. Please check the size of the file`
          store.dispatch(setError(myerror));
          setUploadButtonState(1);
        } else {
          
          Papa.parse(file, {
            complete: (results) => {
              let r = (results.data[0] ?? []).map((x,index) => {
                let g = {} as IDataItem;
                g.id = index;
                g.name = x;
                return g;
                
              })
              // console.log(r)
              setCSVData(r);
            }
          });

          setSelectedFile(file);
          setUploadButtonState(0);
          setUploadState(true)
        }
        break;   
      default:
        console.log("4")
        myerror = Error();
        myerror.name = "File Attachment Error"
        myerror.message = `There was a problem with the attachment you submitted. Please contact IT`
        store.dispatch(setError(myerror));
        setUploadButtonState(1);
        break;
    }
  }

  const onFileUpload = async () => {

    if ((uploadState === true) && ((uploadButtonState === 2) || (uploadButtonState === 3))) {
      setUploadButtonState(3);
    } else {
      if(uploadButtonState === 4) {

      } else {
        let myerror = Error();
          myerror.name = "File Attachment Error"
          myerror.message = `There was a problem with the attachment you submitted. Please check that a file is attached`
          store.dispatch(setError(myerror));
      }
      
    }
    
    // console.log(valueFieldDropDown)
    // console.log(identifyingFieldDropDown)
    // console.log(existingIntegrationDropDown)
  }

  return (
    <div className="h-auto">
      <h1 className="text-2xl font-roboto mb-6">Upload Integration Data</h1>
      <div className="grid gap-10 grid-row-6 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 md:h-full font-roboto">
        <div>
          <h2>Select CSV</h2>
        </div>
        <div className="col-span-2">
          {handleFileChangeStyle()}
        </div>
        <div>
          <h2>Choose Identifying and Friendly Data Fields</h2>
        </div>
        <div className="col-span-2">
          <Dropdown key={renderKey+6} label = "Identifying Field" data={csvData} update={setIdentifyingFieldDropDown}/>
          <div className="h-5"></div>
          <Dropdown key={renderKey} label = "Value Field" data={csvData} update={setValueFieldDropDown}/>

        </div>
        <div>
          <h2>Choose from Existing Integration or Create New</h2>
        </div>
        <div className="col-span-2">
          <Dropdown key={renderKey} label = "Existing Integration" data={integrationData} update={setExistingIntegrationDropDown}/>
        </div>
        <div className="flex justify-left">
        <div className="h-10"></div>
        <button onClick={() => onFileUpload()} className={getUploadButtonStyle(uploadButtonState)}>{getUploadButtonText(uploadButtonState)}</button>
      </div>
      </div>

    </div>
    
  );
}

export default UploadIntegrationData;
