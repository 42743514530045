export const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) // eslint-disable-line
    {
        return (true)
    }
        return (false)
}

export const validateCommaSeperatedListofEmails = (list) => {
    try {
        const emails = list.split(',')
        for (const email of emails) {
            if (!validateEmail(email)) {
                throw new Error("invalid email");
            }
        }
    } catch (error) {
        return false
    }
    return true
}