import {
    SET_APPCONTENTSTATE
  } from '../constants/ActionTypes'
  
  const initialState = [
    {
      appContentState: 2
    }
  ]
  
  export default function appContent(state = initialState, action: any) {
    switch (action.type) {
      case SET_APPCONTENTSTATE:
        return [
          {
            appContentState: action.payload
          }
        ]
      default:
        return state
    }
  }
  