import {
  SET_ERROR
} from '../constants/ActionTypes'

const initialState = [
  {
    myerror: Error()
  }
]

export default function admin(state = initialState, action: any) {
  switch (action.type) {
    case SET_ERROR:
      return [
        {
          myerror: action.error
        }
      ]
    default:
      return state
  }
}
