import { useState } from "react"

export default function Dropdown({value, handler} : {value: string, handler: (a: string) => void}) {
    const [select, setSelect] = useState('')
    const handleSelect = (v:string) => {
        
    }
    return (
        <div className="w-40 lg:w-44 h-7">
            <div className="mb-3 ">
                <select onChange={e=> {handler(e.target.value);}} className="form-select appearance-none
                border 
                py-0.5
                border-[#dad9d9] 
                shadow-inner
                rounded-xl 
                block
                w-full
                px-3
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding bg-no-repeat
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:border-2 focus:outline-none" aria-label="Default select example">
                    <option  value={"User"} selected={value==="User"}>User</option>
                    <option value={"Admin"} selected={value==="Admin"}>Admin</option>
                    <option value={"Service"} selected={value==="Service"}>Service</option>
                </select>
            </div>
        </div>
    )
}